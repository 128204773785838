<template>
    <div>
        <el-button @click="getPort">获取串口</el-button>
        <div id="name"></div>
    </div>
</template>

<script>
export default {
    mounted() {
        if ("serial" in navigator) {
            // The Web Serial API is supported.
            console.log('支持');
        } else {
            console.log('不支持');
        }
    },
    methods: {
        async getPort() {
            const filters = [
                /* {vendorId: 0x1209, productId: 0xa800},
                {vendorId: 0x1209, productId: 0xa850} */
            ];
            // 弹出可选择usb连接
            /* const usbDevice = await navigator.usb.requestDevice({filters: filters}).catch(err => 
                document.getElementById('name').innerText = err
            )
            console.log("🚀 ~ file: demo.vue ~ line 28 ~ getPort ~ usbDevice", usbDevice)
            // 设备开始会话
            await usbDevice.open()
            console.log(usbDevice.opened);
            /* await usbDevice.controlTransferIn('request', 20).then( res => {
                console.log(res, 1111);
            }) */
            let usbDevice;
            navigator.usb.requestDevice({filters: []}).then(res => {
                usbDevice = res
                console.log("🚀 ~ file: demo.vue ~ line 40 ~ navigator.usb.requestDevice ~ usbDevice", usbDevice)
                return usbDevice.open()
            }).then(() => {
                console.log('selectConfiguration');
                return usbDevice.selectConfiguration(1)
            }).then(() => {
                console.log('claimInterface');
                return usbDevice.claimInterface(0)
            })
            .then(() => {
                console.log('transferIn');
                usbDevice.transferIn(5,64)
            }).then(() => {
                console.log('controlTransferOut');
                return usbDevice.controlTransferOut({
                    requestType: 'class',
                    recipient: 'device',
                    request: 0x22,
                    value: 3118,
                    index: 0x00
                })
            }).then((res) => {
                console.log('decode');
                let decoder = new window['TextDecoder'](0);
                console.log(decoder.decode(res.data));
            })
        }
    }
}
</script>

<style>

</style>