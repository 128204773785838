<template>
  <div>
    <div class="computer">
      <footer class="footer">
        <div class="boxs">
          <div class="footer-main">
            <div class="footer-nav" v-for="(item, index) of footerList" :key="index">
              <div class="footer-nav-title" @click="handleClickNav(item.url, item.id)">{{item.title}}</div>
              <div class="footer-nav-item" v-for="(value, key) of item.subnav" :key="key" @click="handleClickNav(value.url, value.id||item.id)">
                <div>{{value.name}}</div>
              </div>
            </div>
            <div class="footer-cooperation">
              <div class="footer-phone">{{contactData.message}}</div>
              <div class="cooperation">
                <div class="footer-nav-item"><div>{{contactData.phoneTranslate}}：&nbsp;{{contactData.phone}}</div></div>
                <div class="footer-nav-item"><div>{{contactData.postbox}}：&nbsp;{{contactData.postboxDet}}</div></div>
                <div class="footer-nav-item"><div>{{contactData.site}}：&nbsp;{{contactData.siteDet}}</div></div>
              </div>
            </div>
            <div class="footer-wechat">
              <div class="footer-wechat-totle">{{contactData.attention}}</div>
              <img class="wechat" src="http://static.parceljet.com/qrcode.png" alt="品晟微信公众号">
            </div>
          </div>
          <div class="copyright" style="color: #4cb4e7;">&copy 2020 <span style="cursor: pointer;">{{contactData.company}}</span><a style="color: #4cb4e7;" href="https://beian.miit.gov.cn" target="_blank"> 粤ICP备2021132055号</a></div>
        </div>
      </footer>
    </div>
    <div class="phone">
      <div class="phone_foot" style="color: #4cb4e7;">&copy 2020 <span style="cursor: pointer;">{{contactData.company}}</span><a style="color: #4cb4e7;" href="https://beian.miit.gov.cn" target="_blank"> 粤ICP备2021132055号</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      footerList: [],
      contactData: {
        message: '',
        phone: '',
        site: '',
        siteDet: '',
        postbox: '',
        postboxDet: '',
        company: '',
        attention: '',
        phoneTranslate: ''
      },
    }
  },
  computed: {
    node(){
      return this.$store.state.node
    },
    languageType(){
      return this.$store.state.languageType
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      if (this.node) {
        this.footerList[0] = {
          title: this.node.siteNav, url: '/', id: 2,
          subnav: [
            // { name: this.node.homeTranslate, url: '/index' },
            { name: this.getLanguageText(this.languageType, '隐私政策'), url: '/privacy' },
            { name: this.getLanguageText(this.languageType, '服务动态及新闻'), url: '/information' },
            { name: this.getLanguageText(this.languageType, '客户登录'), url: '/customerLogin' },
            { name: 'Track', url: '/track' },
          ]
        };
        this.contactData = {
          message: this.node.contactTranslate,
          phone: this.node.phoneValue,
          site: this.node.addressTranslate,
          siteDet: this.node.addressValue,
          postbox: this.node.emailTranslate,
          postboxDet: this.node.emailValue,
          company: this.node.siteName,
          attention: this.node.attention,
          phoneTranslate: this.node.phoneTranslate,
        }
      }
      this.$forceUpdate()
    },
    handleClickNav(url, id) {
      this.$store.commit('setId', id)
      sessionStorage.setItem('currId', id)
      this.$router.push(url)
    }
  },
  watch: {
    node: function (val) {
      this.getData()
    },
    languageType: function (val) {
      this.getData()
    },
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 981px) {
  .boxs {
    width: auto;
  }
}
@media screen and (min-width: 1100px) {
  .footer {
    height: 200px !important;
  }
}
.footer {
  height: 200px;
  // background: #F4F4F4;
  background: #000;
  padding: 15px 300px 15px 258px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  .footer-main {
    // margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    .footer-nav {
      height: 150px;
      // border-right: 1px solid rgba(255,255,255,0.28);
      color: #fff;
      &:first-child {
        min-width: 240px;
        padding-right: 32px;
        box-sizing: border-box;
      }
      &:nth-child(2), &:nth-child(3) {
        width: 239px;
        text-align: center;
      }
      .footer-nav-title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 2px;
        // cursor: pointer;
      }
      .footer-nav-item {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
      &:nth-child(3) .footer-nav-title {
        margin-bottom: 22px;
      }
    }
    .footer-cooperation {
      padding: 0 0px 0 0px;
      height: 150px;
      // border-right: 1px solid rgba(255,255,255,0.28);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .footer-phone {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #fff;
        font-size: 18px;
        .footer-phone-icon {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        .phone-num {
          font-size: 28px;
          margin-top: 2px;
        }
      }
      .cooperation {
        width: 400px;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        & div:first-child {
          margin-bottom: 8px;
        }
      }
    }
    .footer-wechat {
      min-width: 130px;
      height: 150px;
      margin-left: 66px;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      .footer-wechat-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #fff;
        font-size: 18px;
      }
      .wechat {
        width: 110px;
        height: 110px;
        margin-top: 16px;
      }
    }
  }
  .copyright {
    font-size: 16px;
    font-weight: 500;
    color: rgba(105, 104, 104, 0.781);
    .footer-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      position: relative;
      top: 2px;
      background: url('~@/assets/images/footer/icon1.png') no-repeat center center;
      background-size: contain;
      margin-right: 4px;
      &:nth-child(2) {
        background: url('~@/assets/images/footer/icon2.png') no-repeat center center;
        background-size: contain;
      }
    }
  }
}
.phone_footer {
  width: 100vw;
  height: 18vw;
  background-color: #fff;
  color: #fff;
  line-height: 18vw;
  text-align: center;
}
.phone_foot {
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 500;
  color: rgba(105, 104, 104, 0.781);
}
</style>