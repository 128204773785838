import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store";
import 'element-ui/lib/theme-chalk/index.css';
import animate from 'animate.css'
import './assets/css/iconfont.css'
import filters from '@/utils/filters';
import { Icon, Button, Input, Form, FormItem, Row, Col, Message, Dialog, Carousel, CarouselItem, Breadcrumb, BreadcrumbItem, Menu, MenuItem, MenuItemGroup, Pagination, Submenu, Dropdown, DropdownItem, DropdownMenu, ButtonGroup, Collapse, CollapseItem, Empty, Loading, Timeline, TimelineItem, Select, Option, Badge, Image, Radio, RadioGroup, Table, TableColumn } from 'element-ui'
import AOS from 'aos'
import { getLanguageText } from '@/utils/index'
import './assets/js/vantUi'


Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));
AOS.init()

Vue.use(animate)
Vue.use(Icon)
    .use(Button)
    .use(Input)
    .use(Form)
    .use(FormItem)
    .use(Row)
    .use(Col)
    .use(Dialog)
    .use(Carousel)
    .use(CarouselItem)
    .use(Breadcrumb)
    .use(BreadcrumbItem)
    .use(Menu)
    .use(MenuItem)
    .use(MenuItemGroup)
    .use(Pagination)
    .use(Submenu)
    .use(Dropdown)
    .use(DropdownItem)
    .use(DropdownMenu)
    .use(ButtonGroup)
    .use(Collapse)
    .use(CollapseItem)
    .use(Empty)
    .use(Loading)
    .use(Timeline)
    .use(TimelineItem)
    .use(Select)
    .use(Option)
    .use(Badge)
    .use(Image)
    .use(Radio)
    .use(RadioGroup)
    .use(Table)
    .use(TableColumn)
Vue.prototype.$message = Message
Vue.config.productionTip = false
Vue.prototype.getLanguageText = getLanguageText       // 获取对应语言下的文本

// 全局方法
Vue.prototype.$getConsult = function getConsult() {
  store.commit('setId', 4)
  sessionStorage.setItem('currId', 4)
  router.push({name: 'Consultation'})
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
